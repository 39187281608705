
import { defineComponent, reactive } from "vue";
import { dataStatus } from "./bidang-soal";
import { gpf } from "@/utils/global-functions";
import { api } from "@/utils/api-config";
import axios from "axios";

export default defineComponent({
  setup() {
    const configGet = api.configGet();
    const configXwfu = api.configXwfu();
    const listStatus = dataStatus();

    const titleTable: Array<string> = [
      "Title",
      "Jumlah Soal",
      "Kategori Soal",
      "Jenis Soal",
      "Status",
      "Created",
      "#",
    ];

    const dataBS = reactive({
      data: [] as any,
      page: 1,
      pageSize: 10,
      search: "",
      paginate: {
        currentPage: 1,
        endIndex: 4,
        endPage: 2,
        pageSize: 10,
        pages: [],
        startIndex: 0,
        startPage: 0,
        totalItems: 0,
        totalPages: 0,
      } as any,
    });

    const getData = async () => {
      cash("#loadingBSadmin").show();

      const page = dataBS.page;
      const pageSize = dataBS.pageSize;
      const search = dataBS.search;
      axios
        .get(
          api.adminBidangSoal +
            "?search=" +
            search +
            "&page=" +
            page +
            "&pageSize=" +
            pageSize,
          configGet
        )
        .then(async (result: any) => {
          const ds = result.data.diagnostic;
          const rs = result.data.response;
          // console.log("response get du ", rs);

          dataBS.data = rs;
          dataBS.paginate = gpf.paginate(ds.totalData, page, pageSize, 3);
          // console.log("dataBS.paginate ", dataBS.paginate);
          cash("#loadingBSadmin").hide();
        })
        .catch(async (error: any) => {
          cash("#loadingBSadmin").hide();
          // console.log("response get du err ", error.response);
          await gpf.handleEds(error);
        });
    };
    getData();

    const changePS = () => {
      getData();
    };

    const goToPage = (p: number) => {
      dataBS.page = p;
      getData();
    };

    const nextPage = () => {
      dataBS.page = dataBS.page + 1;
      getData();
    };

    const beforePage = () => {
      dataBS.page = dataBS.page - 1;
      getData();
    };

    const lastPage = () => {
      dataBS.page = dataBS.paginate.totalPages;
      getData();
    };

    return {
      titleTable,
      getData,
      dataBS,
      changePS,
      goToPage,
      nextPage,
      beforePage,
      lastPage,
      configGet,
      configXwfu,
      listStatus,
    };
  },
  data() {
    return {
      // for modal data bs
      section: "add",
      msgSuccess: "",

      detailBS: {
        id: 0,
        kode: "",
        title: "",
        jmlSoal: "",
        isActive: "1",
        idCategory: "",
      } as any,

      exDataBS: {
        id: 0,
        kode: "",
        title: "",
        jmlSoal: "",
        isActive: "1",
        idCategory: "",
      },

      exDeleteBS: {
        id: 0,
        title: "",
      },

      listKodeKS: [],
    };
  },
  methods: {
    changeTS(v: any, f: any) {
      const val = v.detail.args[1].key;
      const ex: any = this.exDataBS;
      ex[f] = val;
    },
    async listKategoriSoalApi(callback: any): Promise<void> {
      gpf.gLoading.show();
      axios
        .get(
          api.adminKategoriSoal,
          this.configGet
        )
        .then(async (result: any) => {
          gpf.gLoading.hide();
          const rs = result.data.response;
          // console.log("response get js ", rs);
          this.exDataBS.idCategory = rs[0].id;
          this.listKodeKS = rs.map((e: any) => {
            const obj = {
              title: e.title,
              value: e.id,
              id: e.id,
              kode: e.kode,
            };
            return obj;
          });

          await callback();
        })
        .catch(async (error: any) => {
          gpf.gLoading.hide();
          await gpf.handleEds(error);
        });
    },
    async emptyField() {
      const ex = this.exDataBS;
      ex.kode = "";
      ex.title = "";
      ex.jmlSoal = "";
      ex.isActive = "";
      ex.idCategory = "";
    },
    async addBS() {
      await this.emptyField();
      this.section = "add";
      const showMKS = () =>{
        gpf.showModal("#modal-data-bs");
      };
      this.listKategoriSoalApi( showMKS );
    },
    addBSApi() {
      gpf.gLoading.show();
      const ex = this.exDataBS;
      const params = new URLSearchParams();
      params.append("kode", ex.kode);
      params.append("title", ex.title);
      params.append("jmlSoal", ex.jmlSoal);
      params.append("isActive", ex.isActive);
      params.append("idCategory", ex.idCategory);

      axios
        .post(api.adminBidangSoal, params, this.configXwfu)
        .then(async (result: any) => {
          gpf.gLoading.hide();

          await gpf.handleSds(result);
          await gpf.hideModal("#modal-data-bs");
          await this.emptyField();
          await this.getData();
        })
        .catch(async (error: any) => {
          gpf.gLoading.hide();
          await gpf.handleEds(error);
        });
    },
    editBS(data: any) {
      this.section = "edit";
      const showMKS = () =>{
        gpf.showModal("#modal-data-bs");
      };
      this.listKategoriSoalApi( showMKS );
      gpf.gLoading.show();

      axios
        .get(api.adminBidangSoal + data.id, this.configGet)
        .then(async (result: any) => {
          gpf.gLoading.hide();

          const rs = result.data.response;
          // console.log("response get deu ", rs);
          const ex = this.exDataBS;
          ex.id = rs.id;
          ex.kode = rs.kode;
          ex.title = rs.title;
          ex.jmlSoal = rs.jmlSoal;
          ex.isActive = rs.isActive ? "1" : "0";
          ex.idCategory = rs.idCategory.toString();

          await gpf.hideModal("#modal-data-bs");
        })
        .catch(async (error: any) => {
          gpf.gLoading.hide();
          await gpf.handleEds(error);
        });
    },
    editBSApi(): void {
      gpf.gLoading.show();
      const ex = this.exDataBS;
      const params = new URLSearchParams();
      params.append("kode", ex.kode);
      params.append("title", ex.title);
      params.append("jmlSoal", ex.jmlSoal);
      params.append("isActive", ex.isActive);
      params.append("idCategory", ex.idCategory);

      axios
        .put(api.adminBidangSoal + ex.id, params, this.configXwfu)
        .then(async (result: any) => {
          gpf.gLoading.hide();

          await gpf.handleSds(result);
          await gpf.hideModal("#modal-data-bs");
          await this.emptyField();
          await this.getData();
        })
        .catch(async (error: any) => {
          gpf.gLoading.hide();
          await gpf.handleEds(error);
        });
    },
    detailBSApi(data: any): void {
      gpf.gLoading.show();

      axios
        .get(api.adminBidangSoal + data.id, this.configGet)
        .then(async (result: any) => {
          gpf.gLoading.hide();

          const rs = result.data.response;
          // console.log("response get deu ", rs);

          const ex = this.detailBS;
          ex.id = rs.id;
          ex.kode = rs.kode;
          ex.title = rs.title;
          ex.jmlSoal = rs.jmlSoal;
          ex.isActive = rs.isActive ? "Active" : "Suspend";
          ex.idCategory = rs.SoalCategory.title;

          await gpf.showModal("#modal-detail-bs");
        })
        .catch(async (error: any) => {
          gpf.gLoading.hide();
          await gpf.handleEds(error);
        });
    },
    showPass(id: string) {
      const idHtml = cash("#"+ id);
      if (idHtml.attr("type") === "password") {
        idHtml.attr("type", "text");
      } else {
        idHtml.attr("type", "password");
      }
    },
    deleteBS(data: any): void {
      const ex = this.exDeleteBS;
      ex.id = data.id;
      ex.title = data.title;

      gpf.showModal("#delete-modal-data-bs");
    },
    deleteBSApi() {
      const ex = this.exDeleteBS;

      gpf.gLoading.show();

      axios
        .delete(api.adminBidangSoal + ex.id, this.configGet)
        .then(async (result: any) => {
          gpf.gLoading.hide();

          ex.id = 0;
          ex.title = "";

          await gpf.handleSds(result);
          await gpf.hideModal("#delete-modal-data-bs");
          await this.getData();
        })
        .catch(async (error: any) => {
          gpf.gLoading.hide();
          await gpf.handleEds(error);
        });
    }
  },
});
